import React from 'react'

const NoHiddenFees = ({ illustration, features }) => (
  <div className="container-md padding-xxl relative">
    <div className="grid yg between">
      <div className="col-7">
        <h6 className="eyebrow">Geen verborgen kosten</h6>
        <h2>Over de tarieven salarisadministratie DGA </h2>
        <p>Wanneer je een holding hebt, ben je verplicht om jezelf een DGA-salaris uit te betalen. De administratieve zaken daaromheen besteed je wellicht uit, met alle kosten die daaraan verbonden zijn. Doorgaans worden er extra kosten in rekening gebracht als je bijvoorbeeld verzoekt om jaaropgaven of om specifieke aangiftes. Bij Employes heb je geen verborgen kosten. De tarieven voor salarisadministratie DGA zijn inclusief alle salariszaken. Je weet dus precies waar je aan toe bent.</p>
      </div>

      <div className="hero-illustration">
        <img src={ illustration }/>
      </div>
    </div>

    <div className="grid padding-m-top">
      <div className="col-10">
        <div className="features">
          <ul>
            {
              features.map((feature, idx) => (<li key={`${feature}-${idx}`}>
                <span className="checkmark-container blue">
                  <svg width="13" height="10" viewBox="0 0 13 10">
                    <polyline fill="none" className="checkmark" strokeWidth="2" points="17.532 8 10.02 15.512 7 12.492" transform="translate(-6 -7)"/>
                  </svg>
                </span>
                {feature}
              </li>))
            }
          </ul>
        </div>
      </div>
    </div>
  </div>
)

export default NoHiddenFees
